import React, { useEffect, useState } from "react";
import Select from "react-select";

function CustomSelect({
  options = [],
  name = "",
  placeholder = "",
  label = "",
  handleFieldChange = () => {},
}) {
  const [userTextInput, setUserTextInput] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const handleInputChange = (val, { action }) => {
    if (action === "input-change") {
      setUserTextInput(val);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    setUserTextInput("");
  };

  useEffect(() => {
    let type = "";
    let value;

    if (userTextInput) {
      type = "text";
      value = userTextInput;
    } else {
      type = "select";
      value = selectedValue;
    }

    handleFieldChange({
      name: name,
      type: type,
      value: value,
    });
  }, [userTextInput, selectedValue]);

  return (
    <fieldset className="md:mw-28 w-full mb-2-5">
      <label htmlFor={name} className="block f-size-1-125 color-green mb-1">
        {label}
      </label>

      <Select
        id={`select-${name}`}
        inputValue={userTextInput}
        onInputChange={handleInputChange}
        value={selectedValue}
        onChange={handleSelectChange}
        options={options}
        placeholder={placeholder}
        noOptionsMessage={(val) => `${placeholder} ${val.inputValue} не найден`}
        isClearable={true}
        isSearchable={true}
        classNamePrefix="custom-select"
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? "white" : "white",
          }),
        }}
      />
    </fieldset>
  );
}

export default CustomSelect;
