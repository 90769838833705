import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import { REACT_APP_API_URL } from "../config";

import Header from "../components/Header";
import CustomSelect from "../components/CustomSelect";

function UserReport() {
  const navigate = useNavigate();
  const { type } = useParams();

  const [formData, setFormData] = useState({});
  const [selectOptions, setSelectOptions] = useState({});

  const getIsFormComplete = () => {
    let isFormComplete = formData.region && formData.operator;

    if (type === "vpn") {
      isFormComplete = isFormComplete && formData.vpn;
    }

    if (type === "internet") {
      isFormComplete = isFormComplete && formData.internet_type;
    }

    if (type === "service") {
      isFormComplete = isFormComplete && formData.service;
    }

    return isFormComplete;
  };

  let isFormComplete = getIsFormComplete();

  const getFormData = (name) => {
    const _formData = formData[name]?.value
      ? formData[name].value
      : formData[name];
    return _formData ? _formData : "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const payload = {
      region: getFormData("region"),
      operator: getFormData("operator"),
      vpn: getFormData("vpn"),
      internet_type: getFormData("internet_type"),
      service: getFormData("service"),
    };

    axios
      .post(`${REACT_APP_API_URL}/api/report/`, payload)
      .then((response) => {
        navigate(`/thank-you/${response.data.report_id}`);
      })
      .catch((error) => {
        console.error("There was an error submitting the report!", error);
      });
  };

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_URL}/api/data/`)
      .then((response) => {
        const { regions, operators, vpns, internet_types, services } =
          response.data;

        setSelectOptions({
          regions: regions.map((region) => ({
            value: region.id,
            label: region.name,
          })),
          operators: operators.map((operator) => ({
            value: operator.id,
            label: operator.name,
          })),
          internet_types: internet_types.map((item) => ({
            value: item.id,
            label: item.name,
          })),
          vpns: vpns.map((vpn) => ({ value: vpn.id, label: vpn.name })),
          services: services.map((vpn) => ({ value: vpn.id, label: vpn.name })),
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const handleFieldChange = (data) => {
    setFormData({
      ...formData,
      [data.name]: data.value,
    });
  };

  if (!selectOptions?.regions?.length) {
    return null;
  }

  return (
    <div className="main-body bg-cream md:pb-10">
      <Header />

      <section className="w-full">
        <div className="w-full flex justify-center">
          <div className="container pl-1-25">
            <h2 className="f-size-2 md:f-size-2-5 font-bold mt-5 mb-2-5 md:mb-3-125">
              Для сообщения о сбое выполните форму:
            </h2>

            <div className="flex justify-center">
              <form onSubmit={handleSubmit}>
                <CustomSelect
                  options={selectOptions.regions}
                  name="region"
                  placeholder="Регион"
                  label="Укажите свой регион"
                  handleFieldChange={handleFieldChange}
                />

                <CustomSelect
                  options={selectOptions.operators}
                  name="operator"
                  placeholder="Оператор"
                  label="Укажите оператора связи или провайдера"
                  handleFieldChange={handleFieldChange}
                />

                {type === "internet" ? (
                  <CustomSelect
                    options={selectOptions.internet_types}
                    name="internet_type"
                    placeholder="Тип интернета"
                    label="Укажите тип интернета"
                    handleFieldChange={handleFieldChange}
                  />
                ) : null}

                {type === "vpn" ? (
                  <CustomSelect
                    options={selectOptions.vpns}
                    name="vpn"
                    placeholder="VPN"
                    label="Напишите название vpn или выберите из списка"
                    handleFieldChange={handleFieldChange}
                  />
                ) : null}

                {type === "service" ? (
                  <CustomSelect
                    options={selectOptions.services}
                    name="service"
                    placeholder="Сервис"
                    label="Напишите название сервиса или выберите из списка"
                    handleFieldChange={handleFieldChange}
                  />
                ) : null}

                <div className="flex justify-center">
                  <button
                    className={`color-white br-0-5 ${
                      isFormComplete ? "bg-dark-green" : "bg-gray"
                    } report-btn mw-16-25 md:mw-21-25 f-size-1-125 md:f-size-1-5 font-bold`}
                    disabled={!isFormComplete}
                  >
                    Сообщить о сбое
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default UserReport;
