import Header from "../components/Header";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSiteData } from "../SiteDataContext";

function ThankYou() {
  const { reportId } = useParams();
  const { siteData } = useSiteData();

  return (
    <div className="main-body bg-cream md:pb-10">
      <Header />

      <main className="w-full">
        <section className="w-full flex justify-center">
          <div className="container pl-1-25 md:plr-3">
            <h2 className="text-center lt-0-03 f-size-1-5 fw-700 font-bold mb-2-5 mt-2-7 md:text-left md:lt-0-04 md:f-size-3-125 md:mt-5 md:mb-3-5">
              {siteData?.thankYou?.title}
            </h2>

            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: siteData?.thankYou?.content,
              }}
            />

            <p className="f-size-1 md:f-size-1-25 lh-1-2 md:lh-1-5 mb-4">
              <span className="color-green f-size-1-5 font-bold">
                {reportId}
              </span>
            </p>

            <div className="flex justify-center md:justify-start mb-5 md:mb-5">
              <Link
                to="/"
                className="br-0-5 main-button bg-green f-size-1-125 md:f-size-1-5 md:lt-0-02"
              >
                Наш телеграмм бот
              </Link>
            </div>

            <p className="f-size-1 md:f-size-1-25 lh-1-2 md:lh-1-5 mb-4">
              И не забудь подписаться на наш телеграм канал, чтобы всегда знать,
              что делать в случае сбоев.
            </p>

            <div className="flex justify-center md:justify-start mb-7-5 md:mb-17-5">
              <Link
                to="/"
                className="br-0-5 main-button bg-green f-size-1-125 md:f-size-1-5 md:lt-0-02"
              >
                Наш телеграмм канал
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default ThankYou;
